import revive_payload_client_4sVQNw7RlN from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_uc3JoYcCQq from "/usr/src/app/node_modules/@productdevbook/chatwoot/dist/runtime/plugin.mjs";
import pwa_icons_plugin_LnrhIJeMG7 from "/usr/src/app/.nuxt/pwa-icons-plugin.ts";
import pwa_client_ZuYw5i1i7i from "/usr/src/app/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import plugin_JorSLPZXKr from "/usr/src/app/node_modules/nuxt-umami/dist/runtime/plugin.js";
import plugin_Jozdw60ZsE from "/usr/src/app/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import titles_dw2T9lEw4h from "/usr/src/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.js";
import siteConfig_izaWKZ8rEu from "/usr/src/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.js";
import inferSeoMetaPlugin_Uk9bcXDHeN from "/usr/src/app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.js";
import plugin_client_UYiXMU8ZyN from "/usr/src/app/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import session_client_NxDDSatxox from "/usr/src/app/node_modules/nuxt-auth-utils/dist/runtime/app/plugins/session.client.js";
import slideovers_X7whXyrIWR from "/usr/src/app/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/usr/src/app/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/usr/src/app/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/usr/src/app/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/usr/src/app/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import scrollbars_client_Eg4DPJPcY6 from "/usr/src/app/node_modules/@nuxt/ui-pro/modules/pro/runtime/plugins/scrollbars.client.ts";
import presets_1aypKNZ222 from "/usr/src/app/node_modules/@nuxt/ui-pro/plugins/presets.ts";
import variables_kQtglGecod from "/usr/src/app/node_modules/@nuxt/ui-pro/plugins/variables.ts";
import plugin_client_QHzhVBB2Ll from "/usr/src/app/.nuxt/nuxt-booster/plugin.client.js";
import defaults_aRl2YLnTcb from "/usr/src/app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/defaults.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_uc3JoYcCQq,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_ZuYw5i1i7i,
  plugin_JorSLPZXKr,
  plugin_Jozdw60ZsE,
  titles_dw2T9lEw4h,
  siteConfig_izaWKZ8rEu,
  inferSeoMetaPlugin_Uk9bcXDHeN,
  plugin_client_UYiXMU8ZyN,
  session_client_NxDDSatxox,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  scrollbars_client_Eg4DPJPcY6,
  presets_1aypKNZ222,
  variables_kQtglGecod,
  plugin_client_QHzhVBB2Ll,
  defaults_aRl2YLnTcb
]