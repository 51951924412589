import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import logged_45global from "/usr/src/app/middleware/logged.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  logged_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/usr/src/app/middleware/auth.ts")
}