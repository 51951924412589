import { default as bombera4VhjKG8NsMeta } from "/usr/src/app/pages/bomber.vue?macro=true";
import { default as errornPjfHBf9BUMeta } from "/usr/src/app/pages/error.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as profilempmbOOM2vzMeta } from "/usr/src/app/pages/profile.vue?macro=true";
import { default as signup7mnpplySxfMeta } from "/usr/src/app/pages/signup.vue?macro=true";
export default [
  {
    name: "bomber",
    path: "/bomber",
    meta: bombera4VhjKG8NsMeta || {},
    component: () => import("/usr/src/app/pages/bomber.vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/usr/src/app/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilempmbOOM2vzMeta || {},
    component: () => import("/usr/src/app/pages/profile.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signup7mnpplySxfMeta || {},
    component: () => import("/usr/src/app/pages/signup.vue")
  }
]