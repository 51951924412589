<script setup lang="ts">
const colorMode = useColorMode()
const { isModalVisible, toggle, toggleBubbleVisibility, popoutChatWindow }
    = useChatWoot()
onMounted(() => {
  toggleBubbleVisibility('hide')
})
const color = computed(() =>
  colorMode.value === 'dark' ? '#111827' : 'white',
)

useHead({
  meta: [
    { charset: 'utf-8' },
    { name: 'viewport', content: 'width=device-width, initial-scale=1' },
    { key: 'theme-color', name: 'theme-color', content: color },
  ],
  link: [
    { rel: 'icon', href: '/favicon.ico' },
    { rel: 'apple-touch-icon', href: '/apple-touch-icon-180x180.png' },
  ],

  htmlAttrs: {
    lang: 'ru',
  },
})

useSeoMeta({})
</script>

<template>
  <NuxtPwaAssets />
  <div class="gradient" />
  <NuxtLoadingIndicator />

  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
  <ClientOnly>
    <MiniApp />
  </ClientOnly>
  <UModals />
  <UNotifications />
</template>

<style>
.gradient {
    position: absolute;
    inset: 0;
    pointer-events: none;
    background: radial-gradient(
        50% 50% at 50% 50%,
        rgb(var(--color-primary-500) / 0.25) 0,
        #fff 100%
    );
}

.dark {
    .gradient {
        background: radial-gradient(
            50% 50% at 50% 50%,
            rgb(var(--color-primary-400) / 0.1) 0,
            rgb(var(--color-gray-950)) 100%
        );
    }
}

.overlay {
    background-size: 100px 100px;
    background-image: linear-gradient(
            to right,
            rgb(var(--color-gray-200)) 0.5px,
            transparent 0.5px
        ),
        linear-gradient(
            to bottom,
            rgb(var(--color-gray-200)) 0.5px,
            transparent 0.5px
        );
}
.dark {
    .overlay {
        background-image: linear-gradient(
                to right,
                rgb(var(--color-gray-900)) 0.5px,
                transparent 0.5px
            ),
            linear-gradient(
                to bottom,
                rgb(var(--color-gray-900)) 0.5px,
                transparent 0.5px
            );
    }
}
</style>
