<script lang="ts" setup>
import { BackButton, useWebApp, useWebAppBackButton, useWebAppPopup } from 'vue-tg'

const { isBackButtonVisible } = useWebAppBackButton()
isBackButtonVisible.value = true
const { showAlert } = useWebAppPopup()
const router = useRouter()
const { supported, initData, setSupported } = useMiniappStore()

const { initDataUnsafe, isReady } = useWebApp()

watch(isReady, (value) => {
  if (value) {
    setSupported(true)
    console.log(initDataUnsafe.value)
  }
})
</script>

<template>
  <BackButton text="Main Button" @click="() => router.back()" />
</template>
